import { GradientBackground, GradientOne, GradientTwo } from './Gradient.styled'

export interface GradientProps {
  /** Custom CSS class for styled component overrides */
  className?: string
}

/**
 * A small component that generates a gradient background covering the entire viewport
 * @component
 * @param {GradientProps} props
 */
export default function Gradient({ className }: GradientProps) {
  return (
    <GradientBackground className={className}>
      <GradientOne />
      <GradientTwo />
    </GradientBackground>
  )
}
