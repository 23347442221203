import { Button, Heading, styled } from '@momenthouse/ui'
import Gradient from 'components/Common/Gradient'

const Page = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
})

const Content = styled('div', {
  margin: 'auto',
  textAlign: 'center',
  width: '100%',
  padding: '0px 36px',
  '> button ': {
    padding: '12px 32px',
  },
})

const Title = styled('h1', {
  fontWeight: 500,
  fontSize: '50px',
  fontFamily: 'inherit',
  fontStyle: 'normal',
  lineHeight: '100%',
  letterSpacing: '-0.02em',
  margin: '0',
})

const P = styled('p', {
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '150%',
  color: 'rgb(102, 102, 102)',
  margin: '1em auto',
})

export default function ErrorFallback(props) {
  return (
    <Page>
      <Gradient />
      <Content>
        <Title>Oops!</Title>
        <P>An unexpected error occurred. Please try again.</P>
        <Button size="small" onClick={() => (window.location.href = '/')}>
          Home
        </Button>
      </Content>
    </Page>
  )
}
