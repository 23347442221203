export const baseColors = {
  dark: {
    accent: {
      accent1: '#262626',
      accent2: '#333333',
      accent3: '#444444',
      accent4: '#666666',
      accent5: '#888888',
      accent6: '#999999',
      accent7: '#EAEAEA',
      accent8: '#FAFAFA',
    },
    elevation: {
      elevationBase1: '#000000',
      elevationBase2: '#1A1A1A',
      elevationBase3: '#262626',
      elevationContent1: '#1A1A1A',
      elevationContent2: '#404040',
      elevationContent3: '#4D4D4D',
    },
    semantic: {
      semanticPrimary: '#FFFFFF',
      semanticHover: 'rgba(255, 255, 255, 0.1)',
      semanticContent: '#404040',
      semanticPopover: 'rgba(38, 38, 38, 0.8)',
    },
    text: {
      text100: '#FFFFFF',
      text90: 'rgba(255, 255, 255, 0.9)',
      text80: 'rgba(255, 255, 255, 0.8)',
      text70: 'rgba(255, 255, 255, 0.7)',
      text60: 'rgba(255, 255, 255, 0.6)',
      text50: 'rgba(255, 255, 255, 0.5)',
      text40: 'rgba(255, 255, 255, 0.4)',
      text30: 'rgba(255, 255, 255, 0.3)',
      text20: 'rgba(255, 255, 255, 0.2)',
      text10: 'rgba(255, 255, 255, 0.1)',
      text05: 'rgba(255, 255, 255, 0.05)',
      text00: '#000000',
    },
    color: {
      blue: '#0A84FF',
      cyan: '#64D2FF',
      green: '#30D158',
      indigo: '#5E5CE6',
      mint: '#63E6E2',
      orange: '#FF9F0A',
      pink: '#FF375F',
      purple: '#BF5AF2',
      red: '#FF453A',
      teal: '#40C8E0',
      yellow: '#FFD60A',
    },
  },
  light: {
    accent: {
      accent1: '#FAFAFA',
      accent2: '#EAEAEA',
      accent3: '#999999',
      accent4: '#888888',
      accent5: '#666666',
      accent6: '#444444',
      accent7: '#333333',
      accent8: '#262626',
    },
    elevation: {
      elevationBase1: '#EBEBEB',
      elevationBase2: '#F5F5F5',
      elevationBase3: '#FFFFFF',
      elevationContent1: '#F5F5F5',
      elevationContent2: '#EBEBEB',
      elevationContent3: '#DEDEDE',
    },
    semantic: {
      semanticPrimary: '#000000',
      semanticHover: 'rgba(0, 0, 0, 0.05)',
      semanticContent: '#EBEBEB',
      semanticPopover: 'rgba(255, 255, 255, 0.8)',
    },
    text: {
      text100: '#000000',
      text90: 'rgba(0, 0, 0, 0.9)',
      text80: 'rgba(0, 0, 0, 0.8)',
      text70: 'rgba(0, 0, 0, 0.7)',
      text60: 'rgba(0, 0, 0, 0.6)',
      text50: 'rgba(0, 0, 0, 0.5)',
      text40: 'rgba(0, 0, 0, 0.4)',
      text30: 'rgba(0, 0, 0, 0.3)',
      text20: 'rgba(0, 0, 0, 0.2)',
      text10: 'rgba(0, 0, 0, 0.1)',
      text05: 'rgba(0, 0, 0, 0.05)',
      text00: '#FFFFFF',
    },
    color: {
      blue: '#007AFF',
      cyan: '#32ADE6',
      green: '#34C759',
      indigo: '#5856D6',
      mint: '#00C7BE',
      orange: '#FF9500',
      pink: '#5856D6',
      purple: '#AF52DE',
      red: '#FF3B30',
      teal: '#30B0C7',
      yellow: '#FFCC00',
    },
  },
}

export default baseColors
