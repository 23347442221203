'use client'

import { darken, lighten, readableColor, rgba } from 'polished'

import { createTheme } from '../stitches.config'
import baseColors from './_base'

const background = '#FFFFFF'

const backgroundAlpha = {
  backgroundA10: rgba(background, 0.1),
  backgroundA20: rgba(background, 0.2),
  backgroundA30: rgba(background, 0.3),
  backgroundA40: rgba(background, 0.4),
  backgroundA50: rgba(background, 0.5),
  backgroundA60: rgba(background, 0.6),
  backgroundA70: rgba(background, 0.7),
  backgroundA80: rgba(background, 0.8),
  backgroundA90: rgba(background, 0.9),
  backgroundTranslucent: rgba(background, 0.72),
}

const red = {
  red100: '#FF3333',
  red200: '#FF0000',
}

const primary = {
  primary: '#FFB425',
  primaryForeground: readableColor('#FFB425'),
  primaryLight: lighten(0.03, '#FFB425'),
  primaryLighter: lighten(0.1, '#FFB425'),
  primaryDark: darken(0.03, '#FFB425'),
  primaryDarker: darken(0.1, '#FFB425'),
}

const primaryAlpha = {
  primaryLightA50: rgba(primary.primaryLight, 0.5),
  primaryDarkA50: rgba(primary.primaryDark, 0.5),
  primaryA10: rgba(primary.primary, 0.1),
  primaryA20: rgba(primary.primary, 0.2),
  primaryA30: rgba(primary.primary, 0.3),
  primaryA40: rgba(primary.primary, 0.4),
  primaryA50: rgba(primary.primary, 0.5),
  primaryA60: rgba(primary.primary, 0.6),
  primaryA70: rgba(primary.primary, 0.7),
  primaryA80: rgba(primary.primary, 0.8),
  primaryA90: rgba(primary.primary, 0.9),
}

export const lightTheme = createTheme('light', {
  colors: {
    background,
    ...backgroundAlpha,
    ...baseColors.light.text,
    ...baseColors.light.accent,
    ...baseColors.light.elevation,
    ...baseColors.light.semantic,
    ...primary,
    ...primaryAlpha,
    ...red,
    ...baseColors.light.color,
  },
})

export default lightTheme
