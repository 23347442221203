'use client'

import { concat, filter, uniqueId } from 'lodash'
import React, { createContext, useContext, useState } from 'react'

import { ToastProps } from './Toast'
import { ToastStateProps } from './types'

export const ToastState = createContext<any>({
  addToast: (newToast: ToastProps) => {},
  removeToast: (payload: Partial<ToastProps>) => {},
})

export const useToasts = () => useContext(ToastState)

export const ToastProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  const addToast = (newToast: ToastProps) => {
    // set defaults
    newToast.id = newToast.id || uniqueId('toast')
    newToast.position = newToast.position || 'top-right'
    newToast.timeout = newToast?.timeout ?? 5000

    setToasts((curr) => concat([...curr], newToast))
  }

  const removeToast = (payload: Partial<ToastProps>) => {
    setToasts((curr) => filter([...curr], (toast) => toast.id !== payload.id))
  }

  return (
    <ToastState.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastState.Provider>
  )
}

export default ToastProvider
