import { styled } from '@momenthouse/ui'

export const GradientBackground = styled('div', {
  position: 'fixed',
  top: '0',
  zIndex: -1,
  width: '100vw',
  height: '100vh',
  backgroundAttachment: 'scroll',
  userSelect: 'none',
  pointerEvents: 'none',
})

export const GradientOne = styled('div', {
  position: 'absolute',
  top: '-25%',
  left: '-25%',
  width: '100%',
  maxWidth: 'calc(40vw + 25%)',
  height: '100%',
  maxHeight: 'calc(40vh + 25%)',
  backgroundImage:
    'radial-gradient( 50% 50% at 50% 50%, #f8af5a 0%, rgba(255, 255, 255, 0) 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll',
  transform: 'translate(-25%, -25%) scale(2)',
  opacity: 1,
})

export const GradientTwo = styled('div', {
  position: 'absolute',
  right: '0',
  bottom: '0',
  width: '100%',
  maxWidth: 'calc(40vw + 25%)',
  height: '100%',
  maxHeight: 'calc(40vh + 25%)',
  background:
    'radial-gradient( 50% 50% at 50% 50%, #de8fcd 0%, rgba(255, 255, 255, 0) 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll',
  transform: 'translate(50%, 50%) scale(1.5)',
  opacity: 1,
})
