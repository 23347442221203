import { ComponentProps, VariantProps } from '@stitches/react'
import { ElementType, forwardRef } from 'react'

import { styled } from '../../styles/stitches.config'
import { StitchesPropsToExclude } from '../../utils/type-utils'
import Spinner from '../Spinner/Spinner'

export const StyledButton = styled('button', {
  display: 'inline-block',
  maxWidth: '100%',
  border: 'none',
  br: '$md',
  fontWeight: 600,
  fontFamily: '$sans',
  letterSpacing: '0',
  textAlign: 'center',
  textDecoration: 'none',
  outline: 'none',
  cursor: 'pointer',
  transition: 'background 0.2s ease, box-shadow 0.2s ease',
  appearance: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
  },
  variants: {
    size: {
      small: {
        p: '8px 14px',
        fontSize: '12px',
        lineHeight: 'normal',
      },
      medium: {
        p: '8px 14px',
        fontSize: '14px',
        lineHeight: '21px',
      },
      large: {
        p: '8px 14px',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
    rounded: {
      true: {
        br: '$round',
      },
    },
    loading: {
      true: {
        backgroundColor: 'var(--gray-80)',
        cursor: 'not-allowed',
        opacity: 0.2,
      },
    },
    dynamic: {
      true: {
        color: '$background!important',
        bc: '$text100!important',
        '&:hover': {
          bc: '$accent7!important',
          color: '$background!important',
        },
        '&:disabled': {
          bc: '$accent4!important',
        },
        '&:focus': {
          bc: '$accent7!important',
        },
      },
    },
    variant: {
      '': {
        bc: '#000000',
        color: '#ffffff',
        '&:focus': { bs: '0 0 0 2px #444' },
        '&:hover': {
          bc: '#333',
        },
        '&:disabled': {
          bc: 'var(--gray-80)',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      default: {
        color: '$text100',
        bc: '$elevationBase3',
        '&:hover': {
          // This may not be the correct backgroundColor
          bc: '$semanticContent',
          color: '$text100',
        },
        '&:disabled': {
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      primary: {
        bc: '$primary',
        color: '$primaryForeground',
        '&:hover': {
          color: '$primaryForeground',
          bc: '$primaryDarker',
        },
        '&:focus': { bs: '0 0 0 2px $colors$primaryLighter' },
        '&:disabled': {
          bc: '$primaryLight',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      primaryBlue: {
        color: 'white',
        bc: '$blue',
        '&:hover': {
          color: 'white',
          bc: '$blue',
        },
        '&:focus': { bs: '0 0 0 2px $colors$blue' },
        '&:disabled': {
          bc: '$blue',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      secondaryGrey: {
        color: '$blue',
        bc: '#F0F0F0',
        '&:hover': {
          color: '$blue',
          bc: '#E3E3E3',
        },
        '&:focus': { bs: '0 0 0 2px #E3E3E3' },
        '&:disabled': {
          bc: '#E3E3E3',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      secondaryRed: {
        bc: '#F0F0F0',
        color: '$red200',
        '&:hover': {
          color: '$red200',
          bc: '#E3E3E3',
        },
        '&:focus': { bs: '0 0 0 2px #E3E3E3' },
        '&:disabled': {
          bc: '#E3E3E3',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      secondaryWhite: {
        color: '$blue',
        bc: '#ffffff',
        '&:hover': {
          color: '$blue',
          bc: '#f2f2f2',
        },
        '&:focus': { bs: '0 0 0 2px #f2f2f2' },
        '&:disabled': {
          bc: '#f2f2f2',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      worldthemePrimary: {
        color: 'black',
        bc: '#ffffff',
        '&:hover': {
          color: 'black',
          bc: '#f2f2f2',
        },
        '&:focus': { bs: '0 0 0 2px $accent2' },
        '&:disabled': {
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      worldthemeSecondary: {
        color: '$text100',
        bc: '$accent2',
        '&:hover': {
          color: '$text100',
          bc: '$accent3',
        },
        '&:focus': { bs: '0 0 0 2px $accent2' },
        '&:disabled': {
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      worldthemeSecondaryDark: {
        color: '$text100',
        bc: '#1A1A1A',
        fontSize: '12px',
        lineHeight: '150%',
        fontWeight: 600,
        '&:hover': {
          color: '$text100',
          bc: '#2B2B2B',
        },
        '&:focus': { bs: '0 0 0 2px #1A1A1A' },
        '&:disabled': {
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      spotify: {
        bc: '#1db954',
        color: '#ffffff',
        '&:hover': {
          bc: '#1aa74c',
          color: '#ffffff',
        },
      },
      danger: {
        bc: 'var(--error)',
        color: 'var(--error-light)',
        '&:hover': {
          color: 'var(--error-light)',
          bc: 'var(--error-dark)',
        },
        '&:disabled': {
          bc: 'var(--error-dark)',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      light: {
        bc: '#ffffff',
        color: '#000000',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '&:focus': { bs: '0 0 0 2px #EAEAEA' },
        '&:hover': { color: '#000', bc: 'var(--gray-10)' },
        '&:disabled': {
          bc: '#EAEAEA',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      outline: {
        color: '$text100',
        position: 'relative',
        background: 'none !important',
        '&::before': {
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          border: '1px solid $text100',
          br: '$md',
          color: 'transparent',
          content: "'border'",
        },
        '&:hover': { opacity: 0.8 },
        '&:disabled': {
          bc: '$accent7',
          opacity: 0.6,
          userSelect: 'none',
        },
      },
      plain: {},
    },
  },
  compoundVariants: [
    {
      variant: 'outline',
      rounded: 'true',
      css: {
        br: '$round',
        '&::before': { br: '$round' },
      },
    },
  ],
  defaultVariants: {
    variant: '',
    size: 'medium',
  },
})

type ButtonVariantsType = VariantProps<typeof StyledButton>

type ButtonVariants = {
  [Property in keyof ButtonVariantsType]: Exclude<
    ButtonVariantsType[Property],
    StitchesPropsToExclude
  >
}

type StyledButtonType = ButtonVariants &
  Omit<ComponentProps<typeof StyledButton>, keyof ButtonVariants>

export type ButtonVariant = ButtonVariants['variant']

export interface ButtonProps extends StyledButtonType {
  as?: ElementType
  href?: string
  openNewTab?: boolean
}

/**
 * Component that renders a button element with configurable theme options
 * @component
 * @param {ButtonProps} props
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ as, openNewTab, type = 'submit', ...p }, forwardedRef) => {
    return (
      <StyledButton
        {...p}
        as={as}
        type={type}
        ref={forwardedRef}
        disabled={Boolean(p?.loading || p?.disabled)}
        onClick={(e) => {
          if (p.href && !as) {
            if (openNewTab) {
              window.open(p.href, '_blank')
            } else {
              window.location.href = p.href
            }
          }
          p.onClick?.(e)
        }}
      >
        {p.loading ? <Spinner size={18} /> : p.children}
      </StyledButton>
    )
  }
)

export default Button
