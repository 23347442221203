import { darken, lighten, rgba } from 'polished'

import baseColors from './_base'

const backgrounds = (backgroundBase: string) => {
  return {
    background: backgroundBase,
    backgroundA10: rgba(backgroundBase, 0.1),
    backgroundA20: rgba(backgroundBase, 0.2),
    backgroundA30: rgba(backgroundBase, 0.3),
    backgroundA40: rgba(backgroundBase, 0.4),
    backgroundA50: rgba(backgroundBase, 0.5),
    backgroundA60: rgba(backgroundBase, 0.6),
    backgroundA70: rgba(backgroundBase, 0.7),
    backgroundA80: rgba(backgroundBase, 0.8),
    backgroundA90: rgba(backgroundBase, 0.9),
    backgroundTranslucent: rgba(backgroundBase, 0.6),
  }
}

const red = {
  red100: '#FF1A1A',
  red200: '#EE0000',
}

const primary = {
  primary: baseColors.dark.color.blue,
  primaryForeground: '#FFFFFF',
  primaryLight: lighten(0.03, baseColors.dark.color.blue),
  primaryLighter: lighten(0.1, baseColors.dark.color.blue),
  primaryDark: darken(0.03, baseColors.dark.color.blue),
  primaryDarker: darken(0.1, baseColors.dark.color.blue),
}

const primaryAlpha = {
  primaryLightA50: rgba(primary.primaryLight, 0.5),
  primaryDarkA50: rgba(primary.primaryDark, 0.5),
  primaryA10: rgba(primary.primary, 0.1),
  primaryA20: rgba(primary.primary, 0.2),
  primaryA30: rgba(primary.primary, 0.3),
  primaryA40: rgba(primary.primary, 0.4),
  primaryA50: rgba(primary.primary, 0.5),
  primaryA60: rgba(primary.primary, 0.6),
  primaryA70: rgba(primary.primary, 0.7),
  primaryA80: rgba(primary.primary, 0.8),
  primaryA90: rgba(primary.primary, 0.9),
}

export const worldsDarkThemeColors = {
  ...backgrounds('#1A1A1A'),
  ...baseColors.dark.text,
  ...baseColors.dark.accent,
  ...baseColors.dark.elevation,
  ...baseColors.dark.semantic,
  ...primary,
  ...primaryAlpha,
  ...red,
  ...baseColors.dark.color,
}

export const worldsDarkThemeMobileColors = {
  ...backgrounds('#000000'),
  ...baseColors.dark.text,
  ...baseColors.dark.accent,
  ...baseColors.dark.elevation,
  ...baseColors.dark.semantic,
  ...primary,
  ...primaryAlpha,
  ...red,
  ...baseColors.dark.color,
}

/**
 * After making changes to worldsDarkThemeMobileColors:
 *
 * 1. console.log(worldsDarkThemeMobileColors)
 * 2. Copy the output from your terminal
 * 3. Paste and sync the values into the template literal below
 *
 */
export const worldsDarkThemeMobileCSSText = `
  @media (min-width: 0px) and (max-width: 576px) {
    :root {
      --colors-background: #000000;
      --colors-backgroundA10: rgba(0,0,0,0.1);
      --colors-backgroundA20: rgba(0,0,0,0.2);
      --colors-backgroundA30: rgba(0,0,0,0.3);
      --colors-backgroundA40: rgba(0,0,0,0.4);
      --colors-backgroundA50: rgba(0,0,0,0.5);
      --colors-backgroundA60: rgba(0,0,0,0.6);
      --colors-backgroundA70: rgba(0,0,0,0.7);
      --colors-backgroundA80: rgba(0,0,0,0.8);
      --colors-backgroundA90: rgba(0,0,0,0.9);
      --colors-backgroundTranslucent: rgba(0,0,0,0.6);
      --colors-text100: #FFFFFF;
      --colors-text90: rgba(255, 255, 255, 0.9);
      --colors-text80: rgba(255, 255, 255, 0.8);
      --colors-text70: rgba(255, 255, 255, 0.7);
      --colors-text60: rgba(255, 255, 255, 0.6);
      --colors-text50: rgba(255, 255, 255, 0.5);
      --colors-text40: rgba(255, 255, 255, 0.4);
      --colors-text30: rgba(255, 255, 255, 0.3);
      --colors-text20: rgba(255, 255, 255, 0.2);
      --colors-text10: rgba(255, 255, 255, 0.1);
      --colors-text05: rgba(255, 255, 255, 0.05);
      --colors-text00: #000000;
      --colors-accent1: #262626;
      --colors-accent2: #333333;
      --colors-accent3: #444444;
      --colors-accent4: #666666;
      --colors-accent5: #888888;
      --colors-accent6: #999999;
      --colors-accent7: #EAEAEA;
      --colors-accent8: #FAFAFA;
      --colors-elevationBase1: #000000;
      --colors-elevationBase2: #1A1A1A;
      --colors-elevationBase3: #262626;
      --colors-elevationContent1: #1A1A1A;
      --colors-elevationContent2: #404040;
      --colors-elevationContent3: #4D4D4D;
      --colors-semanticPrimary: #FFFFFF;
      --colors-semanticHover: rgba(255, 255, 255, 0.1);
      --colors-semanticContent: #404040;
      --colors-semanticPopover: rgba(38, 38, 38, 0.8);
      --colors-primary: #0A84FF;
      --colors-primaryForeground: #FFFFFF;
      --colors-primaryLight: #198cff;
      --colors-primaryLighter: #3d9eff;
      --colors-primaryDark: #007cfa;
      --colors-primaryDarker: #006bd6;
      --colors-primaryLightA50: rgba(25,140,255,0.5);
      --colors-primaryDarkA50: rgba(0,124,250,0.5);
      --colors-primaryA10: rgba(10,132,255,0.1);
      --colors-primaryA20: rgba(10,132,255,0.2);
      --colors-primaryA30: rgba(10,132,255,0.3);
      --colors-primaryA40: rgba(10,132,255,0.4);
      --colors-primaryA50: rgba(10,132,255,0.5);
      --colors-primaryA60: rgba(10,132,255,0.6);
      --colors-primaryA70: rgba(10,132,255,0.7);
      --colors-primaryA80: rgba(10,132,255,0.8);
      --colors-primaryA90: rgba(10,132,255,0.9);
      --colors-red100: #FF1A1A;
      --colors-red200: #EE0000;
      --colors-blue: #0A84FF;
      --colors-cyan: #64D2FF;
      --colors-green: #30D158;
      --colors-indigo: #5E5CE6;
      --colors-mint: #63E6E2;
      --colors-orange: #FF9F0A;
      --colors-pink: #FF375F;
      --colors-purple: #BF5AF2;
      --colors-red: #FF453A;
      --colors-teal: #40C8E0;
      --colors-yellow: #FFD60A'
      --colors-highlight: #0077FF;
      --colors-negative: #E14949;
      --colors-yellow100: #F7BE52;
      --colors-yellow200: #FFB425;
      --colors-negativeDark: #D42323;
      --colors-negativeLight: #E87575;
    }
  }
`

export default worldsDarkThemeColors
