import { darken, lighten, readableColor, rgba } from 'polished'

export default function setPrimary(primary) {
  let resolved = primary
  if (!resolved) return
  const d = document.documentElement
  const primaryTokens = {
    '--colors-primary': resolved,
    '--colors-primaryA10': rgba(resolved, 0.1),
    '--colors-primaryA20': rgba(resolved, 0.2),
    '--colors-primaryA30': rgba(resolved, 0.3),
    '--colors-primaryA40': rgba(resolved, 0.4),
    '--colors-primaryA50': rgba(resolved, 0.5),
    '--colors-primaryA60': rgba(resolved, 0.6),
    '--colors-primaryA70': rgba(resolved, 0.7),
    '--colors-primaryA80': rgba(resolved, 0.8),
    '--colors-primaryA90': rgba(resolved, 0.9),
    '--colors-primaryLightA50': rgba(lighten(0.03, resolved), 0.5),
    '--colors-primaryDarkA50': rgba(darken(0.03, resolved), 0.5),
    '--colors-primaryDark': darken(0.03, resolved),
    '--colors-primaryDarker': darken(0.1, resolved),
    '--colors-primaryLight': lighten(0.03, resolved),
    '--colors-primaryLighter': lighten(0.1, resolved),
    '--colors-primaryForeground': readableColor(resolved),
    '--colors-primaryLight30': lighten(0.3, resolved),
  }
  Object.entries(primaryTokens).forEach(([k, v]) => {
    d.style.setProperty(k, v)
  })
}
